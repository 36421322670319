<template>
  <v-app-bar app height="61px" color="bb elevation-0" style="border-bottom: 1px solid red">
    <v-app-bar-nav-icon @click="toggle" v-if="$vuetify.breakpoint.mobile"/>
    <v-app-bar-title>
      {{ title }}
    </v-app-bar-title>
    <v-menu
        v-if="!$vuetify.breakpoint.mobile"
        bottom
        :close-on-click="true"
        :close-on-content-click="true"

        :offset-y="true"
        origin="center center"
        transition="scale-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            text
            v-bind="attrs"
            v-on="on"
        >
          <div class="name-field">
            {{ user.nome }}
          </div>
          <v-icon right class="ml-2">mdi-menu-down</v-icon>
        </v-btn>
      </template>

      <v-list class="pa-0 ma-0">
        <v-list-item @click="onClickLogout">
          Sair
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "AppToolbar",
  computed: {
    ...mapState('route', ['name','meta']),
    ...mapGetters('auth',['user']),
    title() {
      return this.meta?.title || this.name;
    }
  },
  methods: {
    ...mapActions('menu',['toggle']),
    ...mapActions('auth',['doLogout', 'getDefaultLoginPage']),
    async onClickLogout() {
      await this.doLogout();
    }
  },
}
</script>

<style scoped lang="scss">
::v-deep {

  .v-app-bar--fixed {
    background-color: #f5f5f5 !important;
  }

  .v-app-bar-title {
    flex: 1;
    .v-app-bar-title__content {
      width: 100% !important;
    }
  }

  .name-field {
    max-width: 130px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

</style>