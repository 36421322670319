import Vue from 'vue'
import Vuex from 'vuex'
import itemsDominio from "./modules/itemsDominio";
import menu from './modules/menu'
import auth from './modules/auth'
import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    key: '@sysgolfclube',
    modules: ['auth']
})

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        itemsDominio: itemsDominio,
        menu,
        auth
    },
    plugins: [vuexLocal.plugin]
})
