"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAllMenuPages = exports.doLoginAsEmployee = exports.doLoginAsClient = void 0;
const core_1 = require("./core");
function doLoginAsClient(cpf, dtNascimento) {
    return core_1.get('Acesso', 'efetuarLoginCliente', {
        cpf,
        dtNascimento
    })
        .then(({ dados, token }) => {
        return {
            token,
            current: dados[0]
        };
    });
}
exports.doLoginAsClient = doLoginAsClient;
function doLoginAsEmployee(login, senha) {
    return core_1.get('Acesso', 'efetuarLogin', {
        login,
        senha
    })
        .then(({ dados, token }) => {
        return {
            token,
            current: dados[0]
        };
    });
}
exports.doLoginAsEmployee = doLoginAsEmployee;
function getAllMenuPages() {
    return core_1.get('Menu', 'listaPorPerfil')
        .then(({ dados }) => dados);
}
exports.getAllMenuPages = getAllMenuPages;
