import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    INSCRICOES: 'Inscricões'
}

export default [
    {
        name: ROUTER_NAMES.INSCRICOES,
        path: '/inscricoes',
        meta: {
            menu: MENUS.SISTEMA,
            searchAlias: 'Inscricões',
            searchDescription: 'Veja todas as Inscricões cadastradds',
            searchTerms: ['inscricoes'],
            hasRole: 'role/inscricoes'
        },
        component: () => import('@/views/Inscricoes')
    },
]