<template>
  <v-app>
    <app-menu v-if="isAuthenticated"/>
    <app-toolbar v-if="isAuthenticated"/>

    <v-main :class="{'is-mobile': $vuetify.breakpoint.mobile}">

      <v-container fluid class="pa-0">
          <router-view></router-view>
      </v-container>
    </v-main>

  </v-app>
</template>

<script>

import GlobalSearch from "./components/app/GlobalSearch";
import {mapActions, mapGetters, mapState} from "vuex";
import AppMenu from "./components/app/AppMenu";
import AppToolbar from "./components/app/AppToolbar";
import {getAllMenuPages} from "./api/auth";

export default {
  name: 'App',
  components: {AppToolbar, AppMenu,  GlobalSearch},
  computed: {
    ...mapState('route', ['name']),
    ...mapGetters('auth',['isAuthenticated'])
  },
  watch: {
    isAuthenticated: {
      async handler(v) {
        if(!v) {
          this.$router.replace({name: await this.getDefaultLoginPage()});
        } else {
          this.$router.replace({
            name: await this.getDefaultHomePage()
          });
        }
      }
    }
  },
  methods: {
    ...mapActions('menu',['toggle']),
    ...mapActions('auth',['getDefaultLoginPage','getDefaultHomePage'])
  },
  mounted() {
    /*console.log("App.vue");
    getAllMenuPages().then(r => {
      //console.log(r);
    })*/
  },
  created() {
    // window.addEventListener("load", function() { window. scrollTo(0, 0); });
    // document.addEventListener("touchmove", function(e) { e.preventDefault() });
  }
};
</script>

<style lang="scss">

.v-main.is-mobile {
  overflow: auto;
}


.swal2-container.sweet-alert-confirmation {

  &.break {
    #swal2-content {
      overflow: hidden;
    }
  }

  .swal2-modal {
    max-width: 400px;
    padding: 0px;

    .subtitle {
      width: 100%;
    }
  }

  .swal2-content {
    padding: 20px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .swal2-header {
    border-bottom: 1px solid #dedede;
  }

  .swal2-actions {
    margin-top: 0;
    padding: 10px 10px;
    border-top: 1px solid #dedede;
    display: flex;
    justify-content: space-between;
  }
}

.v-dialog.action-sheet {
  bottom: 0;
  position: fixed;
  margin-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  .v-card {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.v-main__wrap {
  background-color: #F0F2F5;
  display: flex;

  > .container {
    flex: 1;
    display: flex;

    > .page {
      flex: 1;
    }
  }

}

.titular {
  color: #2A80B9;
  font-weight: bold;
}
.dependente {
  color: orange;
  font-weight: bold;
}
</style>
