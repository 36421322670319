import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    MATRICULASS: 'Matriculas',
}

export default [
    {
        name: ROUTER_NAMES.MATRICULASS,
        path: '/matriculas',
        props: true,
        meta: {
            menu: MENUS.CADASTRO,
            searchAlias: 'Matriculas',
            searchDescription: 'Veja todas as matriculass cadastradas',
            searchTerms: ['matriculas'],
            hasRole: 'role/matriculas'
        },
        component: () => import('@/views/Matriculas')
    },
]