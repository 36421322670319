import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    LOCAIS: 'Locais'
}

export default [
    {
        name: ROUTER_NAMES.LOCAIS,
        path: '/locais',
        meta: {
            menu: MENUS.CADASTRO,
            searchAlias: 'Locais',
            searchDescription: 'Veja todas as locaiss cadastradas',
            searchTerms: ['locais'],
            hasRole: 'role/locais'
        },
        component: () => import('@/views/Locals')
    },
]