import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    CLIENTESS: 'Clientes'
}

export default [
    {
        name: ROUTER_NAMES.CLIENTESS,
        path: '/clientes',
        meta: {
            menu: MENUS.CADASTRO,
            searchAlias: 'Clientes',
            searchDescription: 'Veja todas as clientess cadastradas',
            searchTerms: ['clientes'],
            hasRole: 'role/clientes'
        },
        component: () => import('@/views/Clientes')
    },
]