import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    COMPETICAO: 'Competicões'
}

export default [
    {
        name: ROUTER_NAMES.COMPETICAO,
        path: '/competicoes',
        meta: {
            menu: MENUS.SISTEMA,
            searchAlias: 'Competicões',
            searchDescription: 'Veja todas as Competicões cadastradas',
            searchTerms: ['competicoes'],
            hasRole: 'role/competicoes'
        },
        component: () => import('@/views/Competicoes')
    },
]