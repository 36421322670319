
export const ROUTER_NAMES = {
    RELATORIO_CLIENTES: 'RelatorioClientes',
    RELATORIO_ANIVERSARIANTES: 'RelatorioAniversariantes',
    RELATORIO_AUDITORIA: 'RelatorioAuditoria',
    RELATORIO_CONTROLE_RECEBIMENTO: 'RelatorioControleRecebimento',
    RELATORIO_HISTORICO_MATRICULA: 'RelatorioHistoricoMatricula',
    RELATORIO_LOG_COMUNICACAO: 'RelatorioLogComunicacao',
    RELATORIO_INSCRICOES: 'RelatorioInscricoes',
}

export default [
    {
        name: ROUTER_NAMES.RELATORIO_CLIENTES,
        path: '/relatorios/clientes',
        meta: {
            title: 'Relatório Cadastro de Clientes'
        },
        component: () => import('@/views/RelatorioClientes')
    },
    {
        name: ROUTER_NAMES.RELATORIO_ANIVERSARIANTES,
        path: '/relatorios/aniversariantes',
        meta: {
            title: 'Relatório de Aniversariantes'
        },
        component: () => import('@/views/RelatorioAniversariantes')
    },
    {
        name: ROUTER_NAMES.RELATORIO_AUDITORIA,
        path: '/relatorios/auditoria',
        meta: {
            title: 'Relatório de Auditoria'
        },
        component: () => import('@/views/RelatorioAuditoria')
    },
    {
        name: ROUTER_NAMES.RELATORIO_CONTROLE_RECEBIMENTO,
        path: '/relatorios/controleRecebimento',
        meta: {
            title: 'Relatório Controle de Recebimento'
        },
        component: () => import('@/views/RelatorioControleRecebimento')
    },
    {
        name: ROUTER_NAMES.RELATORIO_HISTORICO_MATRICULA,
        path: '/relatorios/historicoMatricula',
        meta: {
            title: 'Relatório Matrículas'
        },
        component: () => import('@/views/RelatorioHistoricoMatricula')
    },
    {
        name: ROUTER_NAMES.RELATORIO_LOG_COMUNICACAO,
        path: '/relatorios/logComunicacao',
        meta: {
            title: 'Relatório Log de Comunicação'
        },
        component: () => import('@/views/RelatorioLogComunicacao')
    },
    {
        name: ROUTER_NAMES.RELATORIO_INSCRICOES,
        path: '/relatorios/inscricoes',
        meta: {
            title: 'Relatório Inscrições Torneios'
        },
        component: () => import('@/views/RelatorioInscricoes')
    },
]