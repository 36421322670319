<template>
  <v-navigation-drawer app
                       v-model="opened"
                       color="white">

    <v-list class="pa-0">

      <v-list-item style="height: 60px">
        <v-list-item-title>
          <app-logo height="47px"/>
        </v-list-item-title>
      </v-list-item>
      <v-divider/>
    </v-list>
    <div class="ma-4 mb-1 d-flex align-center justify-start">
      <p class="ma-0 body-2 field-name">
        <strong>
          Olá {{ userName }}
        </strong>
      </p>
    </div>

    <v-list class="pa-0">

      <global-search class="mx-4 my-2"/>
      <v-divider/>
    </v-list>
    <v-list
        class="menu-list"
        nav
        dense
    >

      <v-list-group v-for="m of menu" :key="m.title" :prepend-icon="m.icon" :value="false">
        <template v-slot:activator>
          <v-list-item-title>{{ m.title }}</v-list-item-title>
        </template>

        <v-list-item link class="ml-6" v-for="menuCadastroItem of m.childrens"
                     :to="menuCadastroItem.link"
                     :key="menuCadastroItem.link">
          <v-list-item-title>{{ menuCadastroItem.title }}</v-list-item-title>

        </v-list-item>

      </v-list-group>

    </v-list>
    <v-list class="pa-0">

      <v-divider/>
      <v-list-item @click="onClickLogout">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Sair
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    </v-list>
  </v-navigation-drawer>
</template>

<script>
import GlobalSearch from "./GlobalSearch";
import AppLogo from "./AppLogo";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {MENUS} from "../../router/menu";
import sortBy from 'lodash.sortby';
import {getAllMenuPages} from "../../api/auth";
export default {
  name: "AppMenu",
  components: {AppLogo, GlobalSearch},
  computed: {
    ...mapGetters('auth',['user','isAuthenticated', 'menu', 'authorizedLinks']),
    opened: {
      get () {
        return this.$store.state.menu.opened
      },
      set(value) {
        this.SET_OPENED(value);
      }
    },
    userName: {
      get() {
        return this.user.nome;
      }
    }
  },
  methods: {
    ...mapMutations('menu',['SET_OPENED']),
    ...mapActions('auth',['doLogout', 'getDefaultLoginPage']),
    async onClickLogout() {
      await this.doLogout();
    }
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .menu-list {
      flex: 1;
      overflow: auto;
    }

  }

  .field-name {
    max-width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>