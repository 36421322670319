import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    ETAPA: 'Etapas Evento'
}

export default [
    {
        name: ROUTER_NAMES.ETAPA,
        path: '/etapas',
        meta: {
            menu: MENUS.SISTEMA,
            searchAlias: 'Etapas Evento',
            searchDescription: 'Veja todas as Etapas cadastrados',
            searchTerms: ['etapas'],
            hasRole: 'role/etapas'
        },
        component: () => import('@/views/Etapas')
    },
]