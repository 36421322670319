import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    FUNCIONARIOS: 'Funcionários'
}

export default [
    {
        name: ROUTER_NAMES.FUNCIONARIOS,
        path: '/funcionarios',
        meta: {
            menu: MENUS.SISTEMA,
            searchAlias: 'Funcionários',
            searchDescription: 'Veja todas os Funcionários cadastrados',
            searchTerms: ['funcionarios'],
            hasRole: 'role/funcionarios'
        },
        component: () => import('@/views/Funcionarios')
    },
]