import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/axios';
import './plugins/validators';
import './plugins/toastification';
import './plugins/async-computed';
import './plugins/router-sync';
import './plugins/mask';
import './plugins/moment';
import './plugins/sweet-alert';
import './plugins/utils';
import './plugins/shortkey';
import {findParametroPorNome} from "./api/parametros";

Vue.config.productionTip = process.env.NODE_ENV === 'production';

// Método global que você deseja adicionar
Vue.mixin({
  methods: {
    //metodo global para retornar permissão em um determinado recurso e perfil
    async getParametroPorNome(nome) {
      try {
        const page = await findParametroPorNome(nome);
        let valParametro = page.valParametro;
        return (parseInt(valParametro) === 1 ? true : false);
      } catch (err) {
        return false;
      }
    },
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
